import messages from "@dp-common/locale/translations/en-US.json";
import DPPartsEnLocale from "@dp-parts/locales/translations/en-US.json";
import DPUIEnLocale from "@dp-ui/locales/translations/en-US.json";
import DPUI2EnLocale from "@dp-ui2/locales/translations/_autogenerated/en-US.json";
import antdData from "antd/lib/locale-provider/en_US";

export default {
	locale: "en-US",
	antdData,
	messages: {
		...DPUI2EnLocale,
		...DPUIEnLocale,
		...messages,
		...DPPartsEnLocale,
		openStatsView: "Open Stats View",
		recentSelections: "Recent Selections",
		allFacilities: "All Facilities",
		receiverEmail: "Receiver Email",
		downloadTemplate: "Download Template",
		deleteMeter: "Delete Meter",
		deleteMeterConfirmation: "Are you sure you want to delete this meter?",
		deleteMeterWithReadings: "Delete Meter with Readings",
	},
};
